<template>
  <div>
    <GraphsMenu menu="customer-contracts"></GraphsMenu><br />
    <CCard>
      <CCardHeader>
        <slot name="header">
          Universo Clientes
          <div class="card-header-actions" style="width: 600px; margin-right: 18px;">
            <CRow>      
              <CCol md="3" style="text-align: right;">
                <CButton color="success" size="sm" @click="downloadExcel">Descargar Excel</CButton>
              </CCol>
              <CCol md="3" style="text-align: right;">
                <CSelect class="m-0"
                  :value.sync="monthSelect" 
                  :options="monthsOptions"
                  @change="changeFilters"
                />  
              </CCol>               
              <CCol md="3" style="text-align: right; padding-right: 0px;">
                <CSelect class="m-0" style="float: right; width: 100%;"
                  :value.sync="kamSelect" 
                  :options="kamsOptions"
                  @change="changeFilters"
                />   
              </CCol>
              <CCol md="3" style="text-align: right; padding-right: 0px;">
                <CSelect class="m-0" style="float: right; width: 100%;"
                  :value.sync="customerSelect" 
                  :options="customersOptions"
                  @change="changeFilters"
                />   
              </CCol>             
            </CRow>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow>      
          <CCol md="12">
            <CDataTable 
              :hover="true"
              :striped="true"
              :border="true"
              :small="true"
              :fixed="true"
              :items="items"
              :fields="fields"
              :items-per-page="30"
              :dark="false"
              pagination
              style="width: 100%; font-size: 11px;"
              :table-filter="{label: 'Buscar: ', placeholder: '...'}"
            >
              <template #period="{item}"><td class= "text-center w50">{{item.period}}</td></template>
              <template #customer_name="{item}"><td>{{item.customer_name}}</td></template>
              <template #branch_name="{item}"><td>{{item.branch_name}}</td></template>
              <template #branch_code="{item}"><td class= "text-center w50">{{item.branch_code}}</td></template>
              <template #frequency="{item}"><td class= "text-center w50">{{item.frequency}}</td></template>
              <template #collaborator_salary="{item}"><td class= "text-right w50">{{item.collaborator_salary > 0 ? "$"+item.collaborator_salary : "" }}</td></template>              
              <template #date="{item}"><td class= "text-center w50">{{item.date | date}}</td></template>
              <template #validated_status="{item}"><td class= "text-center w50">{{item.validated_status}}</td></template>
              <template #completed_status="{item}"><td class= "text-center w50">{{item.completed_status}}</td></template>

            </CDataTable>
          </CCol>    
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>

import store from '../../store'
import ws from '../../services/graphs';
import DonoutSemaforoGraph from './graphs/DonoutSemaforoKams.vue';
import GaugeSemaforoGraph from './graphs/GaugeSemaforo.vue';
import GaugePercentSemaforoGraph from './graphs/GaugePercentSemaforo.vue';
import GraphsMenu from './GraphsMenu.vue';

export default {
  name: 'GraphsKams',
  components: {
    DonoutSemaforoGraph,
    GaugeSemaforoGraph,
    GaugePercentSemaforoGraph,
    GraphsMenu
  },
  data () {
    return {
      code: 'general',
      items: [],
      fields: [
          {key: 'period', label: 'Periodo', _style:" font-size: 12px;"},
          {key: 'frequency', label: 'Frecuencia', _style:" font-size: 12px;"},
          {key: 'date', label: 'Dia de Visita', _style:" font-size: 12px;"},
          {key: 'customer_name', label: 'Marca', _style:" font-size: 12px;"},          
          {key: 'store_name', label: 'Cadena', _style:" font-size: 12px;"},
          {key: 'branch_code', label: 'Determinante', _style:" font-size: 12px;"},
          {key: 'branch_name', label: 'Sucursal', _style:" font-size: 12px;"},
          {key: 'branch_state', label: 'Estado', _style:" font-size: 12px;"},
          {key: 'collaborator_name', label: 'Ruta', _style:" font-size: 12px;"},
          {key: 'kam_name', label: 'Kam Ruta', _style:" font-size: 12px;"},          
          {key: 'collaborator_name', label: 'Promotor', _style:" font-size: 12px;"},
          {key: 'collaborator_salary', label: 'Salario', _style:" font-size: 12px;"},
          {key: 'completed_status', label: 'Terminada', _style:" font-size: 12px;"},
          {key: 'validated_status', label: 'Validada', _style:" font-size: 12px;"},
        ],
      customerSelect: "",
      customersOptions: [],
      kamSelect: "",
      kamsOptions: [],
      monthSelect: "202409",
      monthsOptions: [],
    }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;

    let is_administrator = localStorage.getItem("is_administrator");

    if(is_administrator == "false"){
      let is_kam = localStorage.getItem("is_kam");

      if(is_kam == "false"){
        window.location.href = "/#/home"; this.loaded(); return;
      }
      else{
        window.location.href = "/#/welcome"; this.loaded(); return;
      }
    }

    let response = await ws.getKamsSupports(); 
    if(response.type == "success"){
      this.kamsOptions = await this.$parseSelectOptions(response.data, "id", "name",[]);
      if(response.data.length > 0){
        this.kamSelect = this.kamsOptions[0].value;
      }      
    }

    let response3 = await ws.getCustomers(); 
    if(response3.type == "success"){
      this.customersOptions = await this.$parseSelectOptions(response3.data, "id", "name",[{"label": "Todos","value":""}]);
      if(response.data.length > 0){
        this.customerSelect = this.customersOptions[0].value;
      }      
    }

    let response2 = await ws.getMonths(); 
    if(response2.type == "success"){
      this.monthsOptions = await this.$parseSelectOptions(response2.data, "id", "name");
      if(response2.data.length > 0){
        this.monthSelect = this.monthsOptions[0].value;
      }      
    }

    await this.changeFilters();

    this.loaded();
  },
  methods: {
    async changeFilters () {
      this.loading();
        let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
          customer_id : this.customerSelect,
        };
        
        let response_graphs = await ws.getUniverseKams(filters);
        if(response_graphs.type == "success"){
          this.items = response_graphs.data.visits;        
        }
        let response3 = await ws.getCustomers(filters); 
        if(response3.type == "success"){
          this.customersOptions = await this.$parseSelectOptions(response3.data, "id", "name",[{"label": "Todos","value":""}]);          
        }
      this.loaded();
    },
    async downloadExcel(){
      this.loading();

      let filters = {
          kam_id : this.kamSelect,
          month : this.monthSelect,
          customer_id : this.customerSelect,
        };
      await ws.downloadExcelUniverseKams(filters);
      
      this.loaded();
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'danger'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'success'
      }
      return $color
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.warning{
 background-color: #F9B115;
}
.success{
  background-color: #1B9E3E;
}
.danger{
  background-color: #E55353
}
.other{
  background-color: #6b7785
}
.card.success{
  color:black !important;
  font-weight: bold !important;
}
.card-header-actions select{
  font-size: 12px;
}
.card-header{
  font-size: 20px;
  padding: 10px 15px 10px 20px;

}
</style>
